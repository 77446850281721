import produce from 'immer';
import { GET_ALL_LEVELS_SUCCESS, GET_ALL_COURSES_SUCCESS, GET_LEVEL_DETAIL_SUCCESS, SET_LOADER } from './constants';

const INITIAL_STATE = {
    levelsList: [],
    coursesList: [],
    levelDetail: {
        courseId: null,
        levelName: ''
    },
    loader: false
};

const levelsReducer = produce((draft, action) => {
    switch (action.type) {
        case GET_ALL_LEVELS_SUCCESS:
            draft.levelsList = action.payload;
            break;
        case GET_ALL_COURSES_SUCCESS:
            draft.coursesList = action.payload;
            break;
        case GET_LEVEL_DETAIL_SUCCESS:
            draft.levelDetail = action.payload;
            break;
        case SET_LOADER:
            draft.loader = action.payload;
            break;
        default:
    }
}, INITIAL_STATE);

export default levelsReducer;
