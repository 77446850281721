// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconDeviceAnalytics, IconUser, IconUserCheck, IconReceipt2, IconClipboardList } from '@tabler/icons';
import React from 'react';

// constant
const icons = {
    IconDashboard,
    IconDeviceAnalytics,
    IconUserCheck,
    IconUser,
    IconReceipt2,
    IconClipboardList
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const schoolAdminMenu = {
    id: 'school_admin',
    // title: <FormattedMessage id="dashboard" />,
    type: 'group',
    children: [
        {
            id: 'School dashboard',
            title: <FormattedMessage id="school_admin.dashboard" />,
            type: 'item',
            url: '/dashboard',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },

        {
            id: 'Billing',
            title: <FormattedMessage id="school_admin.billing" />,
            type: 'item',
            url: '/billing',
            icon: icons.IconReceipt2,
            breadcrumbs: false
        },
        {
            id: 'Add Teachers',
            title: <FormattedMessage id="school_admin.teachers" />,
            type: 'item',
            url: '/teachers',
            icon: icons.IconUserCheck,
            breadcrumbs: false
        },
        {
            id: 'Grades',
            title: <FormattedMessage id="school_admin.grades" />,
            type: 'item',
            url: '/grades',
            icon: icons.IconClipboardList,
            breadcrumbs: false
        },
        {
            id: 'Students',
            title: <FormattedMessage id="school_admin.students" />,
            type: 'item',
            url: '/student',
            icon: icons.IconUserCheck,
            breadcrumbs: false
        }
    ]
};

export default schoolAdminMenu;
