import { SCORE_LIST, SCORE_LIST_SUCCESS ,GET_ALL_SCORE_LIST,GET_ALL_SCORE_LIST_SUCCESS} from './constants';

export const getAllScoreList = (data) => {
    return {
        type: GET_ALL_SCORE_LIST,
        payload: data
    };
};
export const getAllScoreListSuccess = (data) => {
    return {
        type: GET_ALL_SCORE_LIST_SUCCESS,
        payload: data
    };
};



export const getScoreList = (data) => {
    return {
        type: SCORE_LIST,
        payload: data
    };
};
export const getScoreListSuccess = (data) => {
    return {
        type: SCORE_LIST_SUCCESS,
        payload: data
    };
};

