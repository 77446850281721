import axios from '../../../utils/axios';
import { all, call, fork, put, retry, takeLatest, select } from 'redux-saga/effects';
import { sagaErrorHandler } from 'shared/helperMethods/sagaErrorHandler';
import { makeSelectAuthToken } from '../../../shared/helperMethods/Selectors';
import { SetNotification } from 'shared/helperMethods/setNotification';
import { getAllLevelsSucess, getAllCoursesSucess, getAllLevels, getLevelDetailSuccess ,setLoader} from './actions';
import { GET_ALL_LEVELS, GET_ALL_COURSES, ADD_LEVEL, DELETE_LEVEL, GET_LEVEL_DETAIL, EDIT_LEVEL } from './constants';

function* getAllLevelsRequest({payload}) {
    try {
        const headers = { headers: { 'auth-token': yield select(makeSelectAuthToken()) } };
        const response = yield axios.get(`/levels?search=${payload.search}`, headers);
        yield put(getAllLevelsSucess(response.data));
    } catch (error) {
        yield sagaErrorHandler(error.response.data);
    }
}

export function* watchGetAllLevels() {
    yield takeLatest(GET_ALL_LEVELS, getAllLevelsRequest);
}

function* getAllCoursesRequest({}) {
    try {
        const headers = { headers: { 'auth-token': yield select(makeSelectAuthToken()) } };
        const response = yield axios.get(`/courses`, headers);
        const courses = response.data.map((course) => {
            return {
                value: course.id,
                label: course.title
            };
        });
        yield put(getAllCoursesSucess(courses));
    } catch (error) {
        yield sagaErrorHandler(error.response.data);
    }
}

export function* watchGetAllCourses() {
    yield takeLatest(GET_ALL_COURSES, getAllCoursesRequest);
}

function* addLevelRequest({ payload }) {
    try {
        let data = {
            name: payload.name,
            CourseId: payload.courseId
        };
        const headers = { headers: { 'auth-token': yield select(makeSelectAuthToken()) } };
        const response = yield axios.post(`/levels`, data, headers);
        yield put(getAllLevels({
            search:''
        }));
        yield put(setLoader(false));
        payload.handleClose();
        yield SetNotification('success', 'Level Added Successfully');
    } catch (error) {
        yield put(setLoader(false));
        yield sagaErrorHandler(error.response.data);
    }
}

export function* watchAddLevel() {
    yield takeLatest(ADD_LEVEL, addLevelRequest);
}

function* editLevelRequest({ payload }) {

    try {
        let data = {
            name: payload.name,
            CourseId: payload.courseId
        };
        const headers = { headers: { 'auth-token': yield select(makeSelectAuthToken()) } };
        const response = yield axios.put(`/levels/${payload.id}`, data, headers);
        yield put(getAllLevels({
            search:''
        }));
        yield put(setLoader(false));
        payload.handleClose();
        yield SetNotification('success', 'Level Updated Successfully');
    } catch (error) {
        yield sagaErrorHandler(error.response.data);
        yield put(setLoader(false));
    }
}

export function* watchEditLevel() {
    yield takeLatest(EDIT_LEVEL, editLevelRequest);
}

function* deleteLevelRequest({ payload }) {
    try {
        const headers = { headers: { 'auth-token': yield select(makeSelectAuthToken()) } };
        const response = yield axios.delete(`/levels/${payload.id}`, headers);
        yield put(getAllLevels({
            search:''
        }));
        yield put(setLoader(false));
        payload.handleClose();
        yield SetNotification('success', 'Level Deleted Successfully');
    } catch (error) {
        yield put(setLoader(false));
        yield sagaErrorHandler(error.response.data);
    }
}

export function* watchDeleteLevel() {
    yield takeLatest(DELETE_LEVEL, deleteLevelRequest);
}

function* getLevelDetailRequest({ payload }) {
    try {
        const headers = { headers: { 'auth-token': yield select(makeSelectAuthToken()) } };
        const response = yield axios.get(`/levels/${payload.id}/details`, headers);
        yield put(
            getLevelDetailSuccess({
                levelName: response.data.data.name,
                courseId: response.data.data.CourseId
            })
        );
        yield put(setLoader(false));
        payload.setEditOpen(true);
    } catch (error) {
        yield put(setLoader(false));
        yield sagaErrorHandler(error.response.data);
    }
}

export function* watchGetLevelDetail() {
    yield takeLatest(GET_LEVEL_DETAIL, getLevelDetailRequest);
}

export default function* levelsSaga() {
    yield all([
        fork(watchGetAllLevels),
        fork(watchGetAllCourses),
        fork(watchAddLevel),
        fork(watchDeleteLevel),
        fork(watchGetLevelDetail),
        fork(watchEditLevel)
    ]);
}
