import React, { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import TeacherGuard from './TeacherGuard';

const TeacherDashboard = Loadable(lazy(() => import('views/pages/teacher/dashboard')));
const TeacherStudents = Loadable(lazy(() => import('views/pages/teacher/students')));
const Score = Loadable(lazy(() => import('views/pages/teacher/score')));
const TroubleSpots = Loadable(lazy(() => import('views/pages/teacher/troubleSpots')));
const Skills = Loadable(lazy(() => import('views/pages/teacher/skills')));
const AppUserAccountProfile = Loadable(lazy(() => import('views/users')));



// ==============================|| MAIN ROUTING ||============================== //

const TeacherRoutes = {
    path: '/',
    element: (
        <TeacherGuard>
            <MainLayout />
        </TeacherGuard>
    ),
    children: [
        {  
            path: '/dashboard',
            element: <TeacherDashboard />
        },
        {
            path: '/troublespots',
            element: <TroubleSpots />
        },
        {
            path: '/score',
            element: <Score />
        },
        {
            path: '/students',
            element: <TeacherStudents />
        },
        {
            path: '/user',
            element: <AppUserAccountProfile />
        },
        {
            path: '/skills',
            element: <Skills />
        },
     
       
    ]
};

export default TeacherRoutes;
