// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    IconDashboard,
    IconAward,
    IconBookmark,
    IconUser,
    IconDeviceAnalytics,
    IconUserCheck,
    IconReceipt2,
    IconClipboardList
} from '@tabler/icons';
import React from 'react';

// constant
const icons = {
    IconDashboard,
    IconDeviceAnalytics,
    IconUserCheck,
    IconReceipt2,
    IconClipboardList,
    IconAward,
    IconUser,
    IconBookmark
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const teacherMenu = {
    id: 'teacher',
    // title: <FormattedMessage id="dashboard" />,
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="teacher.dashboard" />,
            type: 'item',
            url: '/dashboard',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'Trouble Spots',
            title: <FormattedMessage id="teacher.troublespots" />,
            type: 'item',
            url: '/troublespots',
            icon: icons.IconBookmark,
            breadcrumbs: false
        },
        {
            id: 'Score',
            title: <FormattedMessage id="teacher.score" />,
            type: 'item',
            url: '/score',
            icon: icons.IconAward,
            breadcrumbs: false
        },
        {
            id: 'Students',
            title: <FormattedMessage id="teacher.students" />,
            type: 'item',
            url: '/students',
            icon: icons.IconUser,
            breadcrumbs: false
        },
        {
            id: 'Skills',
            title: <FormattedMessage id="parent.skills" />,
            type: 'item',
            url: '/skills',
            icon: icons.IconUserCheck,
            breadcrumbs: false
        }
      

     
    ]
};

export default teacherMenu;
