import produce from 'immer';
import { SKILLS_LIST_SUCCESS, GRADE_LIST_SUCCESS } from './constants';

const INITIAL_STATE = {
    skillsList: [],
    gradeList: []
};

const skillsReducer = produce((draft, action) => {
    switch (action.type) {
        case SKILLS_LIST_SUCCESS:
            draft.skillsList = action.payload;
            break;
        case GRADE_LIST_SUCCESS:
            draft.gradeList = action.payload;
            break;
        default:
    }
}, INITIAL_STATE);

export default skillsReducer;
