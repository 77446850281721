import ReactDOM from 'react-dom';

// third party
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from 'App';
import { store, persistor } from 'store';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import 'assets/scss/style.scss';
import config from 'config';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const lang = cookies.get('language')

if (lang === 'en') {
    document.documentElement.style.setProperty('--font-family', `'Roboto' ,sans-serif`);
}

// ==============================|| REACT DOM RENDER  ||============================== //
ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter basename={config.basename}>
                <PayPalScriptProvider
                    options={{
                        'client-id': 'Aexc2MNev-Z0nMPqiyXw7EDWTc4aIdQ59tQY9fqwhgjgW_TOJCIdDUamlKkhsQmQCgwF8E9yLPvc2gpf',
                        // "client-id": "Aexc2MNev-Z0nMPqiyXw7EDWTc4aIdQ59tQY9fqwhgjgW_TOJCIdDUamlKkhsQmQCgwF8E9yLPvc2gpf",
                        // 'Secert-id': 'ECoXJkHLZrfIH9hzHTz1bwTSRXjT-HDe6SDTVzBMMy0Vq3C_ES8RKZGNnSVzUJO4yL_STmb32eOiZ5K0',
                        components: 'buttons',
                        currency: 'USD',
                        vault: "true"
                    }}
                >
                    <App />
                </PayPalScriptProvider>
            </BrowserRouter>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);
