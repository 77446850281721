import axios from '../../../utils/axios';
import { all, call, fork, put, retry, takeLatest, select } from 'redux-saga/effects';
import { SCORE_LIST, GET_ALL_SCORE_LIST } from './constants';
import { getScoreListSuccess, getAllScoreListSuccess } from './actions';
import { sagaErrorHandler } from 'shared/helperMethods/sagaErrorHandler';
import { makeSelectAuthToken } from 'store/Selector';
import { SetNotification } from 'shared/helperMethods/setNotification';

function* getScoreListRequest({ payload }) {
    try {
        const headers = { headers: { 'auth-token': yield select(makeSelectAuthToken()) } };

        let response;
        if (payload.role === 'teacher') {
            response = yield axios.get(
                `/statisticsbyTeacherGrade/${payload.gradeId}?limit=${payload.limit}&page=${payload.page}&search=${payload.search}`,
                headers
            );
        } else {
            response = yield axios.get(
                `/statisticsbyInstitution/${payload.InstitiutionId}?limit=${payload.limit}&page=${payload.page}&search=${payload.search}`,
                headers
            );
        }
        yield put(getScoreListSuccess(response.data.data));
    } catch (error) {
        yield sagaErrorHandler(error.response.data);
    }
}

export function* WatchGetScore() {
    yield takeLatest(SCORE_LIST, getScoreListRequest);
}

function* getAllScoreListRequest({ payload }) {
    try {
        const headers = { headers: { 'auth-token': yield select(makeSelectAuthToken()) } };
        const response = yield axios.get(`statisticsbyInstitution/forExcel/${payload.InstitiutionId}`, headers);
     

        const scoreList = response.data.data;
        const data = scoreList.map((score) => {
          
            return {
                FirstName: score?.Student?.User?.firstName,
                LastName: score?.Student?.User?.lastName,
                Email: score?.Student?.User?.email,
                Skill: score?.Skill?.name,
                Letter: score?.Skill?.Letter?.name,
                TotalScore: score?.totalScore,
                ObtainedScore: score?.score,
                TimeSpent: score?.timeSpent
            };
        });

        yield put(getAllScoreListSuccess(data));
    } catch (error) {
        yield sagaErrorHandler(error.response.data);
    }
}

export function* WatchGetAllScore() {
    yield takeLatest(GET_ALL_SCORE_LIST, getAllScoreListRequest);
}
export default function* scoreSaga() {
    yield all([fork(WatchGetScore), fork(WatchGetAllScore)]);
}
