import { GET_ALL_CATEGORIES, GET_ALL_CATEGORIES_SUCCESS, ADD_QUESTION, DELETE_QUESTION } from './constants';

export const addQuestion = (data) => {
    return {
        type: ADD_QUESTION,
        payload: data
    };
};
export const deleteQuestion = (data) => {
    return {
        type: DELETE_QUESTION,
        payload: data
    };
};
export const getAllCategories = () => {
    return {
        type: GET_ALL_CATEGORIES
    };
};

export const getAllCategoriesSucess = (data) => {
    return {
        type: GET_ALL_CATEGORIES_SUCCESS,
        payload: data
    };
};
