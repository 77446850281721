import {
  GET_GRADE_BY_ID,
  GET_GRADE_BY_ID_SUCCESS,
  GET_STUDENT_BY_ID,
  GET_STUDENT_BY_ID_SUCCESS
} from './constants';

export const getGradeById = (data) => {
    return {
        type: GET_GRADE_BY_ID,
        payload: data
    };
};
export const getGradeByIdSuccess = (data) => {
    return {
        type: GET_GRADE_BY_ID_SUCCESS,
        payload: data
    };
};
export const getStudentById = (data) => {
    return {
        type: GET_STUDENT_BY_ID,
        payload: data
    };
};
export const getStudentByIdSuccess = (data) => {
    return {
        type: GET_STUDENT_BY_ID_SUCCESS,
        payload: data
    };
};


