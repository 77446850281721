import axios from '../../utils/axios';
import { all, call, fork, put, retry, takeLatest ,select } from 'redux-saga/effects';
import { LOGIN, REGISTER, GET_ALL_PLANS, FORGOT_PASSWORD, RESET_PASSWORD ,UPDATE_PROFILE} from 'redux/auth/constants';
import { loginSuccess, getAllPlansSuccess,setLoader,updateProfileSuccess } from './actions';
import { sagaErrorHandler } from 'shared/helperMethods/sagaErrorHandler';
import { SetNotification } from 'shared/helperMethods/setNotification';
import { makeSelectAuthToken } from 'store/Selector';


function* loginUser({ payload }) {
    try {
        let data = {
            email: payload.email,
            password: payload.password
        };
        const response = yield axios.post(`/loginWeb`, data);
        yield(put(setLoader(false)))
        yield SetNotification('success', response.data.message);
        yield put(loginSuccess(response.data.data));
        payload.navigate('/dashboard');
    } catch (error) {
        yield(put(setLoader(false)))
        yield sagaErrorHandler(error.response.data);
    }
}

function* getAllPlansRequest({ payload }) {
    try {
        const response = yield axios.get(`/plans`);
        yield put(getAllPlansSuccess(response.data));
    } catch (error) {
        yield sagaErrorHandler(error.response.data);
    }
}
function* registerRequest({ payload }) {
    let data = {
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email,
        password: payload.password,
        phone: payload.phone,
        // learner1: payload.learner1,
        // learner2: payload.learner2,
        // learner3: payload.learner3,
        // address: payload.address,
        Institutions: {
            // title: payload.title,
            institutionType: payload.institution
        },
        PlanId: payload.PlanId,
        subscriptionId: payload.subscriptionId
    };

    try {
        const response = yield axios.post(`/signup`, data);
        yield SetNotification('success', response.data.message);
        payload.navigate('/');
        yield(put(setLoader(false)))
    } catch (error) {
        yield(put(setLoader(false)))
        
        yield sagaErrorHandler(error.response.data);
    }
}

function* forgetPasswordRequest({ payload }) {
    try {
        let data = {
            email: payload.email
        };
        const response = yield axios.post(`/forgot-password`, data);
        yield SetNotification('success', response.data.message);
        payload.navigate('/');
    } catch (error) {
        yield sagaErrorHandler(error.response.data);
    }
}

function* resetPasswordRequest({ payload }) {
    try {
        let data = {
            id: payload.userId,
            password: payload.password,
            token: payload.token
        };
        const response = yield axios.post(`/reset-password`, data);
        yield SetNotification('success', response.data.message);
        payload.navigate('/');
    } catch (error) {
        yield sagaErrorHandler(error.response.data);
    }
}
function* editProfile({ payload }) {
 
    try {
        const headers = { headers: { 'auth-token': yield select(makeSelectAuthToken()) } };
        const response = yield axios.post(`/user/update/${payload.id}`, payload.formData, headers);
        payload.hanldeModalClose();
        yield put(updateProfileSuccess(response.data.user));
     
        yield SetNotification('success', response.data.message);
    } catch (error) {
        console.log('response.data.message', error);
    }

  
}



export function* watchLogin() {
    yield takeLatest(LOGIN, loginUser);
}
export function* watchRegister() {
    yield takeLatest(REGISTER, registerRequest);
}
export function* watchForgetPassword() {
    yield takeLatest(FORGOT_PASSWORD, forgetPasswordRequest);
}
export function* watchResetPassword() {
    yield takeLatest(RESET_PASSWORD, resetPasswordRequest);
}

export function* watchGetAllPlans() {
    yield takeLatest(GET_ALL_PLANS, getAllPlansRequest);
}
export function* watchEditProfile() {
    yield takeLatest(UPDATE_PROFILE, editProfile);
}

export default function* authSaga() {
    yield all([fork(watchLogin), fork(watchRegister), fork(watchGetAllPlans), fork(watchForgetPassword), fork(watchResetPassword), fork(watchEditProfile)]);
}
