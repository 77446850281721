import produce from 'immer';
import { SET_LOADER, TEACHER_LIST, TEACHER_LIST_SUCCESS, ASSIGNED_TEACHER_SUCCESS, GET_TEACHER_DETAIL_SUCCESS } from './constants';

const INITIAL_STATE = {
    list: [],
    teacherDetail: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        address: '',
        phoneNo: '',
        userType: 'teacher',
        InstitutionId: ''
    },

    loader: false,
    assignedTeachers: []
};

const teacherReducer = produce((draft, action) => {
    switch (action.type) {
        case TEACHER_LIST_SUCCESS:
            draft.list = action.payload;
            break;
        case SET_LOADER:
            draft.loader = action.payload;
            break;
        case ASSIGNED_TEACHER_SUCCESS:
            draft.assignedTeachers = action.payload;
        case GET_TEACHER_DETAIL_SUCCESS:
            draft.teacherDetail = action.payload;
            break;
        default:
    }
}, INITIAL_STATE);

export default teacherReducer;
