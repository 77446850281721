import {LOGIN_SUCCESS, LOGIN, REGISTER, LOGOUT, FORGOT_PASSWORD, RESET_PASSWORD, GET_ALL_PLANS, GET_ALL_PLANS_SUCCESS,UPDATE_PROFILE_SUCCESS,UPDATE_PROFILE,CHANGE_PLAN_SUCCESS,SET_INSTITUTION_TYPE,SET_LOADER,SET_CARD_DETAILS } from './constants';

export const getAllPlans = () => {
    return {
        type: GET_ALL_PLANS
    };
};

export const getAllPlansSuccess = (data) => {
    return {
        type: GET_ALL_PLANS_SUCCESS,
        payload: data
    };
};

export const login = (data) => {
    return {
        type: LOGIN,
        payload: data
    };
};

export const loginSuccess = (data) => {
    return {
        type: LOGIN_SUCCESS,
        payload: data
    };
};

export const register = (data) => {
    return {
        type: REGISTER,
        payload: data
    };
};

export const logout = () => {
    return {
        type: LOGOUT
    };
};

export const forgotPassword = (data) => {
    return {
        type: FORGOT_PASSWORD,
        payload: data
    };
};
export const resetPassword = (data) => {
    return {
        type: RESET_PASSWORD,
        payload: data
    };
};

export const changePlanSuccess = (data) => {
    return {
        type: CHANGE_PLAN_SUCCESS,
        payload: data
    };
};
export const setInstitutionType = (data) => {
    return {
        type: SET_INSTITUTION_TYPE,
        payload: data
    };
};
export const setLoader = (data) => {
    return {
        type: SET_LOADER,
        payload: data
    };
};
export const setCardDetails = (data) => {
    return {
        type: SET_CARD_DETAILS,
        payload: data
    };
};
export const updateProfileSuccess = (data) => {
    return {
        type: UPDATE_PROFILE_SUCCESS,
        payload: data
    };
};
export const updateProfile = (data) => {
    return {
        type: UPDATE_PROFILE,
        payload: data
    };
};
