import React, { lazy } from 'react';
// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import SchoolAdminGuard from './SchoolAdminGuard';

const SchoolAdminDashboard = Loadable(lazy(() => import('views/pages/schoolAdmin/Dashboard')));
const Billing = Loadable(lazy(() => import('views/billing')));
const Teachers = Loadable(lazy(() => import('views/pages/schoolAdmin/teachers')));
const EditTeacher = Loadable(lazy(() => import('views/pages/schoolAdmin/teachers/components/AddEditTeacher')));
const AddTeacher = Loadable(lazy(() => import('views/pages/schoolAdmin/teachers/components/AddEditTeacher')));
const Grades = Loadable(lazy(() => import('views/pages/schoolAdmin/grades/index')));
const Students = Loadable(lazy(() => import('views/pages/schoolAdmin/students')));
const EditStudent = Loadable(lazy(() => import('views/pages/shared/students/components/AddEditStudent')));
const AddStudent = Loadable(lazy(() => import('views/pages/shared/students/components/AddEditStudent')));
const AppUserAccountProfile = Loadable(lazy(() => import('views/users')));

// ==============================|| MAIN ROUTING ||============================== //

const SchoolAdminRoutes = {
    path: '/',
    element: (
        <SchoolAdminGuard>
            <MainLayout />
        </SchoolAdminGuard>
    ),
    children: [
        {
            path: '/dashboard',
            element: <SchoolAdminDashboard />
        },
        {
            path: '/billing',
            element: <Billing />
        },
        {
            path: '/teachers',
            element: <Teachers />
        },

        {
            path: '/editTeacher/:id',
            element: <EditTeacher />
        },
        {
            path: '/addTeacher',
            element: <AddTeacher />
        },

        {
            path: '/grades',
            element: <Grades />
        },
        {
            path: '/student',
            element: <Students />
        },

        {
            path: '/editStudent/:id',
            element: <EditStudent />
        },
        {
            path: '/addStudent',
            element: <AddStudent />
        },
        {
            path: '/user',
            element: <AppUserAccountProfile />
        }
    ]
};

export default SchoolAdminRoutes;
