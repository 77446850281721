import axios from '../../../utils/axios';
import { all, call, fork, put, retry, takeLatest, select } from 'redux-saga/effects';
import { GET_GRADE_BY_ID , GET_STUDENT_BY_ID} from './constants';
import { getGradeByIdSuccess , getStudentByIdSuccess} from './actions';
import { sagaErrorHandler } from 'shared/helperMethods/sagaErrorHandler';
import { makeSelectAuthToken } from 'store/Selector';
import { SetNotification } from 'shared/helperMethods/setNotification';

///</grade By TeacherId/>////
function* gradeByTeacherId({ payload }) {
    const headers = { headers: { 'auth-token': yield select(makeSelectAuthToken()) } };
    try {
        let response = yield axios.get(`/grades/${payload.teacherId}/ByTeacherId`, headers);
        let responseData = response.data;

        yield put(getGradeByIdSuccess(responseData));
      
    } catch (error) {
        yield sagaErrorHandler(error.responseData);
    }
}

export function* WatchgradeByTeacherId() {
    yield takeLatest(GET_GRADE_BY_ID, gradeByTeacherId);
}

///</student By Grade ID/>////
function* getStudentByGrade({ payload }) {
    try {
        const headers = { headers: { 'auth-token': yield select(makeSelectAuthToken()) } };
        let response = yield axios.get(
            `/students/${payload.gradeId}/gradeId?limit=${payload.limit}&page=${payload.page}&search=${payload.search}`,
            headers
        );
        let responseData = response.data.data;
        yield put(getStudentByIdSuccess(responseData));
        // console.log('get student', responseData);

    } catch (error) {
        yield sagaErrorHandler(error.responseData);
    }
}

export function* WatchgetStudentByGrades() {
    yield takeLatest(GET_STUDENT_BY_ID, getStudentByGrade);
}

export default function* studentFromGradeSaga() {
    yield all([fork(WatchgradeByTeacherId), fork(WatchgetStudentByGrades)]);
}
