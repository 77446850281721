import axios from '../../../utils/axios';
import { all, call, fork, put, retry, takeLatest, select } from 'redux-saga/effects';
import { STUDENT_LIST, ADD_STUDENT, DELETE_STUDENT, EDIT_STUDENT, GET_STUDENT_DETAIL, GET_DROPDOWN_VALUES } from './constants';
import { getStudentListSuccess, getStudentList, setLoader, getStudentDetailSuccess, getDropdownValueSuccess } from './actions';
import { sagaErrorHandler } from 'shared/helperMethods/sagaErrorHandler';
import { makeSelectAuthToken } from 'store/Selector';
import { SetNotification } from 'shared/helperMethods/setNotification';

function* getDropDownValuesRequest({ payload }) {
    try {
        const headers = { headers: { 'auth-token': yield select(makeSelectAuthToken()) } };
        const response = yield axios.get(`/grades/${payload.institiutionId}`, headers);

        let data = response.data.map((grade) => {
            return {
                value: grade.id,
                label: grade.name
            };
        });

        yield put(getDropdownValueSuccess(data));
    } catch (error) {
        yield sagaErrorHandler(error.response.data);
    }
}

export function* WatchGetDropDownValues() {
    yield takeLatest(GET_DROPDOWN_VALUES, getDropDownValuesRequest);
}

function* getAllStudentRequest({ payload }) {
    try {
        const headers = { headers: { 'auth-token': yield select(makeSelectAuthToken()) } };
        const response = yield axios.get(
            `/students/byInstituionId/${payload.InstitiutionId}?limit=${payload.limit}&page=${payload.page}&search=${payload.search}`,
            headers
        );

        yield put(getStudentListSuccess(response.data.data));
    } catch (error) {
        yield sagaErrorHandler(error.response.data);
    }
}

export function* WatchGetAllStudent() {
    yield takeLatest(STUDENT_LIST, getAllStudentRequest);
}

function* deleteStudentRequest({ payload }) {
    try {
        const headers = { headers: { 'auth-token': yield select(makeSelectAuthToken()) } };
        const response = yield axios.delete(`/students/${payload.studentId}`, headers);
        yield put(
            getStudentList({ InstitiutionId: payload.institiutionId, limit: payload.limit, page: payload.page, search: payload.search })
        );
        payload.handleClose();
        yield put(setLoader(false));
        yield SetNotification('success', response.data.message);
    } catch (error) {
        yield put(setLoader(false));
        yield sagaErrorHandler(error.response);
    }
}

export function* watchDeleteStudents() {
    yield takeLatest(DELETE_STUDENT, deleteStudentRequest);
}

function* addStudentRequest({ payload }) {
    const headers = { headers: { 'auth-token': yield select(makeSelectAuthToken()) } };

    let data = {
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email,
        password: payload.password,
        phone: payload.phone,
        address: payload.address,
        userType: payload.userType,
        Students: {
            title: payload.firstName + ' ' + payload.lastName,
            institutionType: payload.institutionType,
            InstitutionId: payload.InstitutionId,
            GradeId: payload.gradeId
        }
    };

    try {
        const response = yield axios.post(`/students`, data, headers);
        if (payload.institutionType == 'school') {
            payload.navigate('/student');
        } else {
            payload.navigate('/learners');
        }
        payload.resetForm();
        yield SetNotification('success', response.data.message);
    } catch (error) {
        yield sagaErrorHandler(error.response.data);
    }
}

export function* watchAddStudents() {
    yield takeLatest(ADD_STUDENT, addStudentRequest);
}

function* editStudentsRequest({ payload }) {
    const headers = { headers: { 'auth-token': yield select(makeSelectAuthToken()) } };

    let data = {
        firstName: payload.firstName,
        lastName: payload.lastName,
        phone: payload.phone,
        address: payload.address,
        GradeId: payload.gradeId
    };

    try {
        const response = yield axios.put(`/students/${payload.studentId}`, data, headers);
        if (payload.institutionType == 'school') {
            payload.navigate('/student');
        } else {
            payload.navigate('/learners');
        }

        yield SetNotification('success', response.data.message);
    } catch (error) {
        yield sagaErrorHandler(error.response);
    }
}

export function* watchEditStudents() {
    yield takeLatest(EDIT_STUDENT, editStudentsRequest);
}

function* getStudentDetailRequest({ payload }) {
    try {
        const headers = { headers: { 'auth-token': yield select(makeSelectAuthToken()) } };
        const response = yield axios.get(`/students/${payload.id}/details`, headers);
        let studentDetail = response.data.data;

        let data = {
            firstName: studentDetail?.User.firstName,
            lastName: studentDetail?.User.lastName,
            phoneNo: studentDetail?.User.phone,
            address: studentDetail?.User.address,
            email: studentDetail?.User.email,
            gradeId: studentDetail?.GradeId
        };
        yield put(getStudentDetailSuccess(data));
    } catch (error) {
        yield sagaErrorHandler(error.response);
    }
}

export function* watchGetStudentDetail() {
    yield takeLatest(GET_STUDENT_DETAIL, getStudentDetailRequest);
}

export default function* studentSaga() {
    yield all([
        fork(WatchGetAllStudent),
        fork(watchAddStudents),
        fork(watchDeleteStudents),
        fork(watchEditStudents),
        fork(WatchGetDropDownValues),
        fork(watchGetStudentDetail)
    ]);
}
