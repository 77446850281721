import axios from '../../utils/axios';
import { all, call, fork, put, retry, takeLatest } from 'redux-saga/effects';
import { CHANGE_PLAN, CANCEL_SUBSCRIPTION, CHANGE_CARD, CREATE_SUBSCRIPTION, CREATE_PAYPAL } from './constants';
import { sagaErrorHandler } from 'shared/helperMethods/sagaErrorHandler';
import { SetNotification } from 'shared/helperMethods/setNotification';
import { changePlanSuccess, setLoader, setCardDetails, createPaypalSubscription } from '../auth/actions';
import { useNavigate } from 'react-router-dom';

function* changeCardRequest({ payload }) {
    try {
        let data = {
            userId: payload.userId,
            paymentMethodId: payload.paymentMethodId
        };
        const response = yield axios.post(`stripe/sub/changeCard`, data);

        yield put(setLoader(false));
        yield put(setCardDetails(response.data.cardDetails));
        yield SetNotification('success', 'Card Changed Successfully');
    } catch (error) {
        yield (put(setLoader(false)))
        yield sagaErrorHandler(error.response.data);
    }
}

function* changePlanRequest({ payload }) {
    try {
        let data = {
            PlanId: payload.PlanId,
            subId: payload.subscriptionId,
            userId: payload.userId
        };
        const response = yield axios.post(`stripe/sub/change`, data);

        yield put(
            changePlanSuccess({
                user: response.data.user,
            })
        );
        yield SetNotification('success', response.data.message);
    } catch (error) {
        yield sagaErrorHandler(error.response.data);
    }
}

function* cancelSubscriptionRequest({ payload }) {
    try {
        let data = {
            userId: payload.userId
        };
        const response = yield axios.post(`stripe/sub/cancel`, data);
        yield put(
            changePlanSuccess({
                user: response.data.user,
                subStatus: 'CANCELLED'
            })
        );

        yield SetNotification('success', response.data.message);
    } catch (error) {
        yield sagaErrorHandler(error.response.data);
    }
}

function* createSubscriptionRequest({ payload }) {
    try {
        let data = {
            userId: payload.userId,
            PlanId: payload.PlanId,
            // subId: payload.subscriptionId,
            amount: payload.totalAmount,
            // resetForm: payload.resetForm,

        };
        const response = yield axios.post(`stripe/sub/create`, data);
        // payload.resetForm();
        yield put(
            changePlanSuccess({
                user: response.data.user,
            })
        );
        // window.location.href = response.data.redirectlink;
        yield SetNotification('success', response.data.message);
    } catch (error) {
        yield sagaErrorHandler(error.response.data);
    }
}
function* registerRaypal({ payload }) {

    let data = {
        userId: payload.userId,
        PlanId: payload.PlanId,
        subscriptionId: payload.subscriptionId
    };
    try {
        const response = yield axios.post(`/stripe/sub/create`, data);
        yield SetNotification('success', response.data.message);
        yield put(
            changePlanSuccess({
                user: response.data.user,

            })
        );
        payload.handleCreate();
        // payload.navigate('/billing');
        // payload.setPaypalOpen(false);
        // payload.setOpen(false);
        // payload.resetForm();
        yield (put(setLoader(false)))
    } catch (error) {
        yield (put(setLoader(false)))
        yield sagaErrorHandler(error.response.data);
    }
}

export function* watchChangeCard() {
    yield takeLatest(CHANGE_CARD, changeCardRequest);
}

export function* watchChangePlan() {
    yield takeLatest(CHANGE_PLAN, changePlanRequest);
}

export function* watchCancelSubscription() {
    yield takeLatest(CANCEL_SUBSCRIPTION, cancelSubscriptionRequest);
}

export function* watchCreateSubscription() {
    yield takeLatest(CREATE_SUBSCRIPTION, createSubscriptionRequest);
}
export function* watchRegisterPaypal() {
    yield takeLatest(CREATE_PAYPAL, registerRaypal);
}
export default function* billingSaga() {
    yield all([fork(watchChangePlan), fork(watchCancelSubscription), fork(watchChangeCard), fork(watchCreateSubscription), fork(watchRegisterPaypal)]);
}
