import produce from 'immer';
import { GET_ALL_SKILLS_SUCCESS, GET_SKILL_DETAIL_SUCCESS } from './constants';

const INITIAL_STATE = {
    skillsList: [],
    skillDetail: {
        skillName: '',
        skillId:null,
    }
};

const skillsReducer = produce((draft, action) => {
    switch (action.type) {
        case GET_ALL_SKILLS_SUCCESS:
            draft.skillsList = action.payload;
            break;

        case GET_SKILL_DETAIL_SUCCESS:
            draft.skillDetail = action.payload;
            break;
        default:
    }
}, INITIAL_STATE);

export default skillsReducer;
