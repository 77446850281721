import axios from '../../../utils/axios';
import { all, call, fork, put, retry, takeLatest, select } from 'redux-saga/effects';
import { GET_SCHOOL_DASHBOARD_DATA } from './constants';
import { getSchoolDashboardDataSuccess } from './actions';
import { sagaErrorHandler } from 'shared/helperMethods/sagaErrorHandler';
import { makeSelectAuthToken } from 'store/Selector';
import { SetNotification } from 'shared/helperMethods/setNotification';

function* getSchoolDashboardDataRequest({ payload }) {
    try {
        const headers = { headers: { 'auth-token': yield select(makeSelectAuthToken()) } };
        const response = yield axios.get(`/dashboard/admin/${payload?.id}`, headers);

        yield put(getSchoolDashboardDataSuccess(response.data.data));
    } catch (error) {
        yield sagaErrorHandler(error.response.data);
    }
}

export function* WatchSchoolDashboardData() {
    yield takeLatest(GET_SCHOOL_DASHBOARD_DATA, getSchoolDashboardDataRequest);
}

export default function* schoolAdminDashboardSaga() {
    yield all([fork(WatchSchoolDashboardData)]);
}
