// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconDeviceAnalytics, IconUserCheck, IconReceipt2, IconClipboardList } from '@tabler/icons';

// constant
const icons = {
    IconDashboard,
    IconDeviceAnalytics,
    IconUserCheck,
    IconReceipt2,
    IconClipboardList
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const superAdminMenu = {
    id: 'super_admin',
    // title: <FormattedMessage id="dashboard" />,
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="superAdmin.dashboard" />,
            type: 'item',
            url: '/dashboard',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'Levels',
            title: <FormattedMessage id="superAdmin.levels" />,
            type: 'item',
            url: '/levels',
            icon: icons.IconUserCheck,
            breadcrumbs: false
        },
        // {
        //     id: 'homes',
        //     title: <FormattedMessage id="superAdmin.homes" />,
        //     type: 'item',
        //     url: '/homes',
        //     icon: icons.IconUserCheck,
        //     breadcrumbs: true
        // },
        // {
        //     id: 'Schools',
        //     title: <FormattedMessage id="superAdmin.schools" />,
        //     type: 'item',
        //     url: '/schools',
        //     icon: icons.IconUserCheck,
        //     breadcrumbs: true
        // },
        {
            id: 'Users',
            title: <FormattedMessage id="superAdmin.users" />,
            type: 'item',
            url: '/users',
            icon: icons.IconUserCheck,
            breadcrumbs: false
        },
       
    ]
};

export default superAdminMenu;
