import {
    GET_ALL_LEVELS,
    GET_ALL_LEVELS_SUCCESS,
    GET_ALL_COURSES,
    GET_ALL_COURSES_SUCCESS,
    ADD_LEVEL,
    EDIT_LEVEL,
    DELETE_LEVEL,
    GET_LEVEL_DETAIL,
    GET_LEVEL_DETAIL_SUCCESS,
    SET_LOADER
} from './constants';

export const setLoader = (data) => {
    return {
        type: SET_LOADER,
        payload: data
    };
};


export const getAllLevels = (data) => {
    return {
        type: GET_ALL_LEVELS,
        payload:data,
    };
};

export const getAllLevelsSucess = (data) => {
    return {
        type: GET_ALL_LEVELS_SUCCESS,
        payload: data
    };
};

export const getAllCourses = () => {
    return {
        type: GET_ALL_COURSES
    };
};

export const getAllCoursesSucess = (data) => {
    return {
        type: GET_ALL_COURSES_SUCCESS,
        payload: data
    };
};

export const addLevel = (data) => {
    return {
        type: ADD_LEVEL,
        payload: data
    };
};
export const editLevel = (data) => {
    return {
        type: EDIT_LEVEL,
        payload: data
    };
};


export const deleteLevel = (data) => {
    return {
        type: DELETE_LEVEL,
        payload: data
    };
};

export const getLevelDetail = (data) => {
    return {
        type: GET_LEVEL_DETAIL,
        payload: data
    };
};

export const getLevelDetailSuccess = (data) => {
    return {
        type: GET_LEVEL_DETAIL_SUCCESS,
        payload: data
    };
};
