import axios from '../../../utils/axios';
import { all, call, fork, put, retry, takeLatest, select } from 'redux-saga/effects';
import { sagaErrorHandler } from 'shared/helperMethods/sagaErrorHandler';
import { makeSelectAuthToken } from '../../../shared/helperMethods/Selectors';
import { SetNotification } from 'shared/helperMethods/setNotification';
import { getAllSkillsSucess, getAllSkills, getSkillDetailSuccess } from './actions';
import { setLoader } from 'redux/superAdmin/levels/actions';
import { GET_ALL_SKILLS, ADD_SKILL, DELETE_SKILL, GET_SKILL_DETAIL, EDIT_SKILL } from './constants';

function* getAllSkillsRequest({ payload }) {
    try {
        const headers = { headers: { 'auth-token': yield select(makeSelectAuthToken()) } };
        const response = yield axios.get(
            `/skills/${payload.id}?limit=${payload.limit}&page=${payload.page}&search=${payload.search}`,
            headers
        );
        yield put(getAllSkillsSucess(response.data));
    } catch (error) {
        yield sagaErrorHandler(error.response.data);
    }
}

export function* watchGetAllSkills() {
    yield takeLatest(GET_ALL_SKILLS, getAllSkillsRequest);
}

function* addSkillRequest({ payload }) {
    try {
        let data = {
            name: payload.name,
            LetterId: payload.id
        };
        const headers = { headers: { 'auth-token': yield select(makeSelectAuthToken()) } };
        const response = yield axios.post(`/skills`, data, headers);
        yield put(
            getAllSkills({
                id: payload.id,
                limit: payload.limit,
                page: payload.page,
                search: payload.search
            })
        );
        payload.handleClose();
        yield put(setLoader(false));
      
        yield SetNotification('success', 'Skill Added Successfully');
    } catch (error) {
        yield put(setLoader(false));
        yield sagaErrorHandler(error.response.data);
    }
}

export function* watchAddSkill() {
    yield takeLatest(ADD_SKILL, addSkillRequest);
}

function* editSkillRequest({ payload }) {

    try {
        let data = {
            name: payload.name
        };
        const headers = { headers: { 'auth-token': yield select(makeSelectAuthToken()) } };
        const response = yield axios.put(`/skills/${payload.skillId}`, data, headers);
        yield put(
            getAllSkills({
                id: payload.id,
                limit: payload.limit,
                page: payload.page,
                search: payload.search
            })
        );
        payload.handleClose();
        yield put(setLoader(false));
       
        yield SetNotification('success', 'Level Updated Successfully');
    } catch (error) {
        yield put(setLoader(false));
        yield sagaErrorHandler(error.response.data);
    }
}

export function* watchEditSkill() {
    yield takeLatest(EDIT_SKILL, editSkillRequest);
}

function* deleteSkillRequest({ payload }) {
    try {
        const headers = { headers: { 'auth-token': yield select(makeSelectAuthToken()) } };
        const response = yield axios.delete(`/skills/${payload.skillId}`, headers);
        yield put(
            getAllSkills({
                id: payload.id,
                limit: payload.limit,
                page: payload.page,
                search: payload.search
            })
        );
       
        payload.handleClose();
        yield put(setLoader(false));
        yield SetNotification('success', 'Skill Deleted Successfully');
    } catch (error) {
        yield put(setLoader(false));
        yield sagaErrorHandler(error.response.data);
    }
}

export function* watchDeleteSkill() {
    yield takeLatest(DELETE_SKILL, deleteSkillRequest);
}

function* getSkillDetailRequest({ payload }) {
    try {
        const headers = { headers: { 'auth-token': yield select(makeSelectAuthToken()) } };
        const response = yield axios.get(`/skills/${payload.id}/details`, headers);
        yield put(
            getSkillDetailSuccess({
                skillName: response.data.data.name,
                skillId: response.data.data.id
            })
        );
        
        payload.setEditOpen(true);
        yield put(setLoader(false));
    } catch (error) {
        yield put(setLoader(false));
        yield sagaErrorHandler(error.response.data);
    }
}

export function* watchGetSkillDetail() {
    yield takeLatest(GET_SKILL_DETAIL, getSkillDetailRequest);
}

export default function* skillsSaga() {
    yield all([fork(watchGetAllSkills), fork(watchAddSkill), fork(watchDeleteSkill), fork(watchGetSkillDetail), fork(watchEditSkill)]);
}
