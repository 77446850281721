import axios from '../../../utils/axios';
import { all, call, fork, put, retry, takeLatest, select } from 'redux-saga/effects';
import { GET_SHARED_DASHBOARD_DATA_REQUEST } from './constants';
import { getSharedDashboardDataSuccess } from './actions';
import { sagaErrorHandler } from 'shared/helperMethods/sagaErrorHandler';
import { makeSelectAuthToken } from 'store/Selector';
import { SetNotification } from 'shared/helperMethods/setNotification';

function* getSharedDashboardDataRequest({ payload }) {
    try {
        const headers = { headers: { 'auth-token': yield select(makeSelectAuthToken()) } };
        let response;
        if (payload.role == 'parent') {
            response = yield axios.get(`/dashboard/admin/${payload?.id}`, headers);
        }
        if (payload.role == 'teacher') {
            response = yield axios.get(`/dashboard/teacher/209`, headers);
        }

        yield put(getSharedDashboardDataSuccess(response.data.data));
    } catch (error) {
        yield sagaErrorHandler(error.response.data);
    }
}

export function* WatchDashboardData() {
    yield takeLatest(GET_SHARED_DASHBOARD_DATA_REQUEST, getSharedDashboardDataRequest);
}

export default function* parentTeacherDashboardSaga() {
    yield all([fork(WatchDashboardData)]);
}
