import produce from 'immer';
import { GET_SHARED_DASHBOARD_DATA_SUCCESS } from './constants';

const INITIAL_STATE = {
    dashboardData: [],
    
};

const parentTeacherDashboard = produce((draft, action) => {
    switch (action.type) {
        case GET_SHARED_DASHBOARD_DATA_SUCCESS:
            draft.dashboardData = action.payload;
            break;
      

        default:
    }
}, INITIAL_STATE);

export default parentTeacherDashboard;
