import axios from '../../../utils/axios';
import { all, call, fork, put, retry, takeLatest, select } from 'redux-saga/effects';
import { GET_ALL_GRADES, ADD_GRADES, EDIT_GRADES, DELETE_GRADES, GET_GRADE_DETAIL } from './constants';
import { getAllGradesSuccess, setLoader, getAllGrades, getGradeDetailSuccess } from './actions';
import { sagaErrorHandler } from 'shared/helperMethods/sagaErrorHandler';
import { makeSelectAuthToken } from 'store/Selector';
import { SetNotification } from 'shared/helperMethods/setNotification';

function* getGradeDetailRequest({ payload }) {
    try {
        const headers = { headers: { 'auth-token': yield select(makeSelectAuthToken()) } };
        let response = yield axios.get(`/grades/${payload.gradeId}/details`, headers);

        yield put(
            getGradeDetailSuccess({
                gradeName: response.data.name,
                teacherId: response.data.TeacherId
            })
        );
    } catch (error) {
        yield sagaErrorHandler(error.responseData);
    }
}

export function* WatchGetGradeDetail() {
    yield takeLatest(GET_GRADE_DETAIL, getGradeDetailRequest);
}

function* getAllGrade({ payload }) {
    try {
        const headers = { headers: { 'auth-token': yield select(makeSelectAuthToken()) } };
        let response = yield axios.get(
            `/grades/${payload.InstitiutionId}/ByInstitution?limit=${payload.limit}&page=${payload.page}&search=${payload.search}`,
            headers
        );
        let responseData = response.data;
        yield put(getAllGradesSuccess(responseData));
    } catch (error) {
        yield sagaErrorHandler(error.responseData);
    }
}

export function* WatchGetAllGrades() {
    yield takeLatest(GET_ALL_GRADES, getAllGrade);
}

function* addGrade({ payload }) {
    const headers = { headers: { 'auth-token': yield select(makeSelectAuthToken()) } };

    let data = {
        name: payload.name,
        InstitutionId: payload.institutionId,
        TeacherId: payload.TeacherId
    };

    try {
        const response = yield axios.post(`/grades`, data, headers);
        yield put(
            getAllGrades({ InstitiutionId: payload.institutionId, limit: payload.limit, page: payload.page, search: payload.search })
        );
        payload.handleClose();
        yield put(setLoader(false));
        yield SetNotification('success', 'Grade Created Successfully');
    } catch (error) {
        yield put(setLoader(false));
        yield sagaErrorHandler(error.response.data);
    }
}

export function* watchAddGrade() {
    yield takeLatest(ADD_GRADES, addGrade);
}

function* editGrade({ payload }) {
    const headers = { headers: { 'auth-token': yield select(makeSelectAuthToken()) } };
    let data = {
        name: payload.name,
        TeacherId: payload.TeacherId
    };

    try {
        const response = yield axios.put(`/grades/` + payload.id, data, headers);
        yield put(
            getAllGrades({ InstitiutionId: payload.institutionId, limit: payload.limit, page: payload.page, search: payload.search })
        );
        payload.handleClose();
        yield put(setLoader(false));

        yield SetNotification('success', 'update Grade successfully');
    } catch (error) {
        yield put(setLoader(false));
        yield sagaErrorHandler(error.response.data);
    }
}

export function* watchEditGrade() {
    yield takeLatest(EDIT_GRADES, editGrade);
}

function* deleteGrade({ payload }) {
    const headers = { headers: { 'auth-token': yield select(makeSelectAuthToken()) } };

    try {
        const response = yield axios.delete(`grades/` + payload.GradeId, headers);
        yield put(
            getAllGrades({
                InstitiutionId: payload.institiutionId,
                limit: payload.limit,
                page: payload.page,
                search: payload.search
            })
        );

        payload.handleClose();
        yield put(setLoader(false));

        yield SetNotification('success', 'Delete Grade Successfully');
    } catch (error) {
        yield put(setLoader(false));
        yield sagaErrorHandler(error.response);
    }
}

export function* watchDeleteGrades() {
    yield takeLatest(DELETE_GRADES, deleteGrade);
}

export default function* gradeSaga() {
    yield all([fork(WatchGetAllGrades), fork(watchAddGrade), fork(watchEditGrade), fork(watchDeleteGrades), fork(WatchGetGradeDetail)]);
}
