import axios from '../../../utils/axios';
import { all, call, fork, put, retry, takeLatest, select } from 'redux-saga/effects';
import { SKILLS_LIST, GRADE_LIST } from './constants';
import { getSkillsListSuccess, getGradeListSuccess } from './actions';
import { sagaErrorHandler } from 'shared/helperMethods/sagaErrorHandler';
import { makeSelectAuthToken } from 'store/Selector';
import { SetNotification } from 'shared/helperMethods/setNotification';

function* getAllSkillsListRequest({ payload }) {
    try {
        const headers = { headers: { 'auth-token': yield select(makeSelectAuthToken()) } };
        let response;
        if(payload.role==='teacher'){
             response = yield axios.get(
                `/statistics/Skills/${payload.InstitutionId}/${payload.gradeId}?limit=${payload.limit}&page=${payload.page}&search=${payload.search}`,
                headers
            )
        }
       else{
        response = yield axios.get(
            `/statistics/Skills/${payload.InstitutionId}?limit=${payload.limit}&page=${payload.page}&search=${payload.search}`,
            headers
        )
       }

        yield put(getSkillsListSuccess(response.data.data));
    } catch (error) {
        yield sagaErrorHandler(error.response.data);
    }
}

export function* WatchGetAllSkills() {
    yield takeLatest(SKILLS_LIST, getAllSkillsListRequest);
}

function* getGradeListRequest({ payload }) {
    try {
        const headers = { headers: { 'auth-token': yield select(makeSelectAuthToken()) } };
        const response = yield axios.get(`/grades/${payload.teacherId}/ByTeacherId`, headers);
        let arr = [];
        response?.data.map((item) => {
            arr.push({
                label: item.name,
                value: item.id
            });
        });
        yield put(getGradeListSuccess(arr));
    } catch (error) {
        yield sagaErrorHandler(error.response.data);
    }
}

export function* WatchGetAllGrades() {
    yield takeLatest(GRADE_LIST, getGradeListRequest);
}

export default function* studentSkillsSaga() {
    yield all([fork(WatchGetAllSkills), fork(WatchGetAllGrades)]);
}
