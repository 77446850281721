import {
    SET_LOADER,
    TEACHER_LIST,
    TEACHER_LIST_SUCCESS,
    ADD_NEW_TEACHER,
    DELETE_TEACHER,
    EDIT_TEACHER,
    ASSIGNED_TEACHER,
    ASSIGNED_TEACHER_SUCCESS,
    GET_TEACHER_DETAIL,
    GET_TEACHER_DETAIL_SUCCESS
} from './constants';

export const getTeacherDetail = (data) => {
    return {
        type: GET_TEACHER_DETAIL,
        payload: data
    };
};
export const getTeacherDetailSuccess = (data) => {
    return {
        type: GET_TEACHER_DETAIL_SUCCESS,
        payload: data
    };
};


export const setLoader = (data) => {
    return {
        type: SET_LOADER,
        payload: data
    };
};

export const getTeacherList = (data) => {
    return {
        type: TEACHER_LIST,
        payload: data
    };
};
export const getTeacherListSuccess = (data) => {
    return {
        type: TEACHER_LIST_SUCCESS,
        payload: data
    };
};
export const assigndTeacher = (data) => {
    return {
        type: ASSIGNED_TEACHER,
        payload: data
    };
};
export const assigndTeacherSuccess = (data) => {
    return {
        type: ASSIGNED_TEACHER_SUCCESS,
        payload: data
    };
};
export const addNewTeacher = (data) => {
    return {
        type: ADD_NEW_TEACHER,
        payload: data
    };
};
export const deleteTeacher = (data) => {
    return {
        type: DELETE_TEACHER,
        payload: data
    };
};

export const editTeacher = (data) => {
    return {
        type: EDIT_TEACHER,
        payload: data
    };
};
