import { TROUBLE_SPOTS_LIST, TROUBLE_SPOTS_LIST_SUCCESS,RECOMMEND_SKILL } from './constants';

export const getTroubleSpotsList = (data) => {
    return {
        type: TROUBLE_SPOTS_LIST,
        payload: data
    };
};
export const getTroubleSpotsListSuccess = (data) => {
    return {
        type: TROUBLE_SPOTS_LIST_SUCCESS,
        payload: data
    };
};

export const recommendSkill = (data) => {
    return {
        type: RECOMMEND_SKILL,
        payload: data
    };
};