import {
    GET_ALL_USERS,
    GET_ALL_USERS_SUCCESS
} from './constants';

export const getAllUsers = (data) => {
    return {
        type: GET_ALL_USERS,
        payload: data
    };
};

export const getAllUsersSuccess = (data) => {
    return {
        type: GET_ALL_USERS_SUCCESS,
        payload: data
    };
};

