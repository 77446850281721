import { GET_SCHOOL_DASHBOARD_DATA,GET_SCHOOL_DASHBOARD_SUCCESS } from './constants';

export const getSchoolDashboardData = (data) => {
    return {
        type: GET_SCHOOL_DASHBOARD_DATA,
        payload: data
    };
};
export const getSchoolDashboardDataSuccess = (data) => {
    return {
        type: GET_SCHOOL_DASHBOARD_SUCCESS,
        payload: data
    };
};

