import { GET_SHARED_DASHBOARD_DATA_REQUEST,GET_SHARED_DASHBOARD_DATA_SUCCESS } from './constants';

export const getSharedDashboardData = (data) => {
    return {
        type: GET_SHARED_DASHBOARD_DATA_REQUEST,
        payload: data
    };
};
export const getSharedDashboardDataSuccess = (data) => {
    return {
        type: GET_SHARED_DASHBOARD_DATA_SUCCESS,
        payload: data
    };
};

