import React, { lazy } from 'react';
// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import ParentGuard from './ParentGuard';

const ParentDashboard = Loadable(lazy(() => import('views/pages/parent/dashboard')));
const Billing = Loadable(lazy(() => import('views/billing/index')));
const Learners = Loadable(lazy(() => import('views/pages/parent/learners')));
const EditLearner = Loadable(lazy(() => import('views/pages/shared/students/components/AddEditStudent')));
const AddLearner = Loadable(lazy(() => import('views/pages/shared/students/components/AddEditStudent')));
const Troublespots = Loadable(lazy(() => import('views/pages/parent/troubleSpots')));
const Score = Loadable(lazy(() => import('views/pages/parent/score')));
const Skills = Loadable(lazy(() => import('views/pages/parent/skills')));
const AppUserAccountProfile = Loadable(lazy(() => import('views/users')));

// ==============================|| MAIN ROUTING ||============================== //

const ParentRoutes = {
    path: '/',
    element: (
        <ParentGuard>
            <MainLayout />
        </ParentGuard>
    ),
    children: [
        {
            path: '/dashboard',
            element: <ParentDashboard />
        },
        {
            path: '/billing',
            element: <Billing />
        },
        {
            path: '/learners',
            element: <Learners />
        },
        {
            path: '/editLearner/:id',
            element: <EditLearner />
        },
        {
            path: '/addLearner',
            element: <AddLearner />
        },

        {
            path: '/troublespots',
            element: <Troublespots />
        },
       
        {
            path: '/score',
            element: <Score />
        },
        {
            path: '/skills',
            element: <Skills />
        },
        {
            path: '/user',
            element: <AppUserAccountProfile />
        },
    
    ]
};

export default ParentRoutes;
