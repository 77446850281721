import {
    SET_LOADER,
    STUDENT_LIST,
    STUDENT_LIST_SUCCESS,
    ADD_STUDENT,
    DELETE_STUDENT,
    EDIT_STUDENT,
    GET_STUDENT_DETAIL,
    GET_STUDENT_DETAIL_SUCCESS,
    GET_DROPDOWN_VALUES,
    GET_DROPDOWN_VALUES_SUCCESS
} from './constants';

export const getDropdownValue = (data) => {
    return {
        type: GET_DROPDOWN_VALUES,
        payload: data
    };
};

export const getDropdownValueSuccess = (data) => {
    return {
        type: GET_DROPDOWN_VALUES_SUCCESS,
        payload: data
    };
};

export const getStudentDetail = (data) => {
    return {
        type: GET_STUDENT_DETAIL,
        payload: data
    };
};
export const getStudentDetailSuccess = (data) => {
    return {
        type: GET_STUDENT_DETAIL_SUCCESS,
        payload: data
    };
};

export const setLoader = (data) => {
    return {
        type: SET_LOADER,
        payload: data
    };
};

export const getStudentList = (data) => {
    return {
        type: STUDENT_LIST,
        payload: data
    };
};
export const getStudentListSuccess = (data) => {
    return {
        type: STUDENT_LIST_SUCCESS,
        payload: data
    };
};

export const addStudent = (data) => {
    return {
        type: ADD_STUDENT,
        payload: data
    };
};
export const deleteStudent = (data) => {
    return {
        type: DELETE_STUDENT,
        payload: data
    };
};

export const editStudent = (data) => {
    return {
        type: EDIT_STUDENT,
        payload: data
    };
};
