import axios from 'axios';

export const API_URL = process.env.API_URL || 'https://app-api.tabshuraapplication.com/api/v1';

const instance = axios.create({
    baseURL: API_URL,
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
    },
});

instance.interceptors.response.use(
    function (response) {
        // Do something with response data
        return response;
    }
);

export default instance;