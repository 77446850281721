import { SKILLS_LIST,SKILLS_LIST_SUCCESS ,GRADE_LIST,GRADE_LIST_SUCCESS } from './constants';

export const getSkillsList = (data) => {
    return {
        type: SKILLS_LIST,
        payload: data
    };
};
export const getSkillsListSuccess = (data) => {
    return {
        type: SKILLS_LIST_SUCCESS,
        payload: data
    };
};
// get grades action

export const getGradeList = (data) => {
    return {
        type: GRADE_LIST,
        payload: data
    };
};
export const getGradeListSuccess = (data) => {
    return {
        type: GRADE_LIST_SUCCESS,
        payload: data
    };
};
