import produce from 'immer';
import { SET_LOADER, STUDENT_LIST_SUCCESS, GET_STUDENT_DETAIL_SUCCESS, GET_DROPDOWN_VALUES_SUCCESS } from './constants';

const INITIAL_STATE = {
    list: [],
    studentDetail: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        address: '',
        phoneNo: '',
        userType: 'teacher',
        InstitutionId: ''
    },

    loader: false,
    dropDownValues: []
};

const studentReducer = produce((draft, action) => {
    switch (action.type) {
        case STUDENT_LIST_SUCCESS:
            draft.list = action.payload;
            break;
        case SET_LOADER:
            draft.loader = action.payload;
            break;

        case GET_STUDENT_DETAIL_SUCCESS:
            draft.studentDetail = action.payload;
            break;
        case GET_DROPDOWN_VALUES_SUCCESS:
            draft.dropDownValues = action.payload;
            break;
        default:
    }
}, INITIAL_STATE);

export default studentReducer;
