import superAdminMenu from './SuperAdmin';
import schoolAdminMenu from './SchoolAdmin';
import parentMenu from './Parent';
import teacherMenu from './Teacher';
// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [superAdminMenu, schoolAdminMenu, parentMenu, teacherMenu]
};

export default menuItems;
