import { combineReducers } from 'redux';
import customizationReducer from './themeReducers/customizationReducer';
import snackbarReducer from './themeReducers/snackbarReducer';
import cartReducer from './themeReducers/cartReducer';
import kanbanReducer from './themeReducers/kanbanReducer';
import AuthReducer from 'redux/auth/reducers';
import levelsReducer from 'redux/superAdmin/levels/reducers';
import skillsReducer from 'redux/superAdmin/skills/reducers';
import questionsReducer from 'redux/superAdmin/questions/reducers';
import superAdminDashboard from 'redux/superAdmin/dashboard/reducers';
import teacherReducer from 'redux/schoolAdmin/teachers/reducers';
import studentReducer from 'redux/schoolAdmin/students/reducers';
import gradeReducer from 'redux/schoolAdmin/grades/reducers';
import schoolAdminDashboard from 'redux/schoolAdmin/dashboard/reducers';
import troubleSpotsReducer from 'redux/shared/troubleSpots/reducers';
import scoreReducer from 'redux/shared/score/reducers';
import studentSkillsReducer from 'redux/shared/skills/reducers';
import usersReducer from 'redux/superAdmin/users/reducers';
import parentTeacherDashboard from 'redux/shared/dashboard/reducers';
import studentFromGradeReducer from '../redux/shared/student/reducers';
// ==============================|| COMBINE REDUCER ||============================== //

const rootReducer = combineReducers({
    customization: customizationReducer,
    snackbar: snackbarReducer,
    cart: cartReducer,
    kanban: kanbanReducer,
    //Website Reducer
    auth: AuthReducer,
    levels: levelsReducer,
    skills: skillsReducer,
    questions: questionsReducer,
    teachers: teacherReducer,
    students: studentReducer,
    troubleSpots: troubleSpotsReducer,
    score: scoreReducer,
    grades: gradeReducer,
    studentSkills: studentSkillsReducer,
    users:usersReducer,
    sharedDashboard:parentTeacherDashboard,
    schoolAdminDashboard:schoolAdminDashboard,
    superAdminDashboard:superAdminDashboard,
    getGrade:studentFromGradeReducer,
});

export default rootReducer;
