export const LOGIN = 'login';
export const LOGIN_SUCCESS = 'login_success';
export const REGISTER = 'register';
export const LOGOUT = 'logout';
export const FORGOT_PASSWORD = 'forgot_password';
export const RESET_PASSWORD = 'reset_password';
export const GET_ALL_PLANS = 'get_all_plans';
export const GET_ALL_PLANS_SUCCESS = 'get_all_plans_success';
export const CHANGE_PLAN_SUCCESS ='change_plan_success';
export const SET_INSTITUTION_TYPE = 'set_institution_type';
export const SET_LOADER = 'set_loader';
export const SET_CARD_DETAILS = 'set_card_details';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
