import produce from 'immer';
import { TROUBLE_SPOTS_LIST_SUCCESS } from './constants';

const INITIAL_STATE = {
    troubleSpotsList: []
};

const troubleSpotsReducer = produce((draft, action) => {
    switch (action.type) {
        case TROUBLE_SPOTS_LIST_SUCCESS:
            draft.troubleSpotsList = action.payload;
            break;

        default:
    }
}, INITIAL_STATE);

export default troubleSpotsReducer;
