import produce from 'immer';
import { GET_SCHOOL_DASHBOARD_SUCCESS } from './constants';

const INITIAL_STATE = {
    dashboardData: [],
    
};

const schoolAdminDashboard = produce((draft, action) => {
    switch (action.type) {
        case GET_SCHOOL_DASHBOARD_SUCCESS:
            draft.dashboardData = action.payload;
            break;
      

        default:
    }
}, INITIAL_STATE);

export default schoolAdminDashboard;
