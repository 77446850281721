import produce from 'immer';
import { GET_GRADE_BY_ID_SUCCESS,GET_STUDENT_BY_ID_SUCCESS } from './constants';

const INITIAL_STATE = {
 
    getGradeById: [
    
    ],
    studentByGradeId: []
    
};

const studentFromGradeReducer = produce((draft, action) => {
    switch (action.type) {
        case GET_GRADE_BY_ID_SUCCESS:
            draft.getGradeById = action.payload;
            break;
            case GET_STUDENT_BY_ID_SUCCESS:
                draft.studentByGradeId = action.payload;
            
                break;
        default:
    }
}, INITIAL_STATE);

export default studentFromGradeReducer;
