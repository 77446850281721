import produce from 'immer';
import { SET_LOADER, GET_ALL_GRADES_SUCCESS, GET_GRADE_DETAIL_SUCCESS } from './constants';

const INITIAL_STATE = {
    gradeList: [],
    loader: false,
    gradeDetail: {
        gradeName: '',
        teacherId: null,
    }
};

const gradeReducer = produce((draft, action) => {
    switch (action.type) {
        case GET_ALL_GRADES_SUCCESS:
            draft.gradeList = action.payload;
            break;
        case GET_GRADE_DETAIL_SUCCESS:
            draft.gradeDetail = action.payload;
            break;
        case SET_LOADER:
            draft.loader = action.payload;
            break;
        default:
    }
}, INITIAL_STATE);

export default gradeReducer;
