// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    IconDashboard,
    IconAward,
    IconUser,
    IconDeviceAnalytics,
    IconBookmark,
    IconUserCheck,
    IconReceipt2,
    IconClipboardList
} from '@tabler/icons';
import React from 'react';

// constant
const icons = {
    IconDashboard,
    IconDeviceAnalytics,
    IconUserCheck,
    IconReceipt2,
    IconClipboardList,
    IconAward,
    IconBookmark,
    IconUser
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const parentMenu = {
    id: 'parent',
    // title: <FormattedMessage id="dashboard" />,
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="parent.dashboard" />,
            type: 'item',
            url: '/dashboard',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },

        {
            id: 'billing',
            title: <FormattedMessage id="parent.billing" />,
            type: 'item',
            url: '/billing',
            icon: icons.IconReceipt2,
            breadcrumbs: false
        },
        {
            id: 'Add Learners',
            title: <FormattedMessage id="parent.learners" />,
            type: 'item',
            url: '/learners',
            icon: icons.IconUserCheck,
            breadcrumbs: false
        },
        {
            id: 'Trouble Spots',
            title: <FormattedMessage id="parent.troublespots" />,
            type: 'item',
            url: '/troublespots',
            icon: icons.IconUser,
            breadcrumbs: false
        },
        {
            id: ' Score',
            title: <FormattedMessage id="parent.score" />,
            type: 'item',
            url: '/score',
            icon: icons.IconAward,
            breadcrumbs: false
        },
        {
            id: 'Skills',
            title: <FormattedMessage id="parent.skills" />,
            type: 'item',
            url: '/skills',
            icon: icons.IconUserCheck,
            breadcrumbs: false
        }
    ]
};

export default parentMenu;
