import axios from '../../../utils/axios';
import { all, call, fork, put, retry, takeLatest, select } from 'redux-saga/effects';
import { sagaErrorHandler } from 'shared/helperMethods/sagaErrorHandler';
import { makeSelectAuthToken } from '../../../shared/helperMethods/Selectors';
import { SetNotification } from 'shared/helperMethods/setNotification';
import { getAllUsersSuccess } from './actions';
import { setLoader } from 'redux/superAdmin/levels/actions';
import { GET_ALL_USERS } from './constants';

function* getAllUsersRequest({ payload }) {
  
    try {
        const headers = { headers: { 'auth-token': yield select(makeSelectAuthToken()) } };
        const response = yield axios.get(
            `/user/getAllUsers?limit=${payload.limit}&page=${payload.page}&search=${payload.search}&userType=${payload.userType}`,
            headers
        );
       
        yield put(getAllUsersSuccess(response.data));
    } catch (error) {
        yield sagaErrorHandler(error.response.data);
    }
}

export function* watchGetAllUsers() {
    yield takeLatest(GET_ALL_USERS, getAllUsersRequest);
}



export default function* usersSaga() {
    yield all([fork(watchGetAllUsers)]);
}
