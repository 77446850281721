import {
    GET_ALL_SKILLS,
    GET_ALL_SKILLS_SUCCESS,
    ADD_SKILL,
    EDIT_SKILL,
    DELETE_SKILL,
    GET_SKILL_DETAIL,
    GET_SKILL_DETAIL_SUCCESS
} from './constants';

export const getAllSkills = (data) => {
    return {
        type: GET_ALL_SKILLS,
        payload: data
    };
};

export const getAllSkillsSucess = (data) => {
    return {
        type: GET_ALL_SKILLS_SUCCESS,
        payload: data
    };
};

export const addSkill = (data) => {
    return {
        type: ADD_SKILL,
        payload: data
    };
};
export const editSkill = (data) => {
    return {
        type: EDIT_SKILL,
        payload: data
    };
};

export const deleteSkill = (data) => {
    return {
        type: DELETE_SKILL,
        payload: data
    };
};

export const getSkillDetail = (data) => {
    return {
        type: GET_SKILL_DETAIL,
        payload: data
    };
};

export const getSkillDetailSuccess = (data) => {
    return {
        type: GET_SKILL_DETAIL_SUCCESS,
        payload: data
    };
};
