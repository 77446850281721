import { all } from 'redux-saga/effects';
import authSaga from '../redux/auth/sagas';
import billingSaga from '../redux/billing/sagas';
import levelsSaga from '../redux/superAdmin/levels/sagas';
import skillsSaga from 'redux/superAdmin/skills/sagas';
import questionsSaga from 'redux/superAdmin/questions/sagas';
import superAdminDashboardSaga from '../redux/superAdmin/dashboard/sagas';
import troubleSpotsSaga from 'redux/shared/troubleSpots/sagas';
import teacherSaga from 'redux/schoolAdmin/teachers/sagas';
import studentSaga from 'redux/schoolAdmin/students/sagas';
import gradeSaga from 'redux/schoolAdmin/grades/sagas';
import schoolAdminDashboardSaga from 'redux/schoolAdmin/dashboard/sagas';
import scoreSaga from 'redux/shared/score/sagas';
import studentSkillsSaga from 'redux/shared/skills/sagas';
import usersSaga from 'redux/superAdmin/users/sagas';
import parentTeacherDashboardSaga from 'redux/shared/dashboard/sagas';
import studentFromGradeSaga from '../redux/shared/student/sagas';
export default function* rootSaga() {
    yield all([
        authSaga(),
        billingSaga(),
        levelsSaga(),
        skillsSaga(),
        questionsSaga(),
        teacherSaga(),
        studentSaga(),
        gradeSaga(),
        troubleSpotsSaga(),
        scoreSaga(),
        studentSkillsSaga(),
        usersSaga(),
        parentTeacherDashboardSaga(),
        schoolAdminDashboardSaga(),
        superAdminDashboardSaga(),
        studentFromGradeSaga(),
    ]);
}
