import produce from 'immer';
import { SCORE_LIST_SUCCESS, GET_ALL_SCORE_LIST_SUCCESS } from './constants';

const INITIAL_STATE = {
    scoreList: [],
    allScoreList: []
};

const scoreReducer = produce((draft, action) => {
    switch (action.type) {
        case SCORE_LIST_SUCCESS:
            draft.scoreList = action.payload;
            break;
        case GET_ALL_SCORE_LIST_SUCCESS:
            draft.allScoreList = action.payload;
            break;

        default:
    }
}, INITIAL_STATE);

export default scoreReducer;
