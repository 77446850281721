import axios from '../../../utils/axios';
import { all, call, fork, put, retry, takeLatest, select } from 'redux-saga/effects';
import { TROUBLE_SPOTS_LIST, RECOMMEND_SKILL } from './constants';
import { getTroubleSpotsListSuccess, getTroubleSpotsList } from './actions';
import { sagaErrorHandler } from 'shared/helperMethods/sagaErrorHandler';
import { makeSelectAuthToken } from 'store/Selector';
import { SetNotification } from 'shared/helperMethods/setNotification';

function* getAllTroubleSpotsListRequest({ payload }) {
    try {
        const headers = { headers: { 'auth-token': yield select(makeSelectAuthToken()) } };
        let response
        if(payload.role=='teacher'){
            response = yield axios.get(
                `/troubleSpot/ByGradeId/${payload.gradeId}?limit=${payload.limit}&page=${payload.page}&search=${payload.search}`,
                headers
            );
    
        }
        else{
            response = yield axios.get(
                `/troubleSpot/${payload.institutionId}?limit=${payload.limit}&page=${payload.page}&search=${payload.search}`,
                headers
            );
    
        }
    
        yield put(getTroubleSpotsListSuccess(response.data));
    } catch (error) {
        yield sagaErrorHandler(error.response.data);
    }
}

export function* WatchGetAllTroubleSpots() {
    yield takeLatest(TROUBLE_SPOTS_LIST, getAllTroubleSpotsListRequest);
}

function* recommendSkillRequest({ payload }) {
    let data = {
        SkillId: payload.SkillId,
        StudentId: payload.StudentId,
        TeacherId: payload.TeacherId ? payload.TeacherId : null,
        InstitutionId: payload.InstitutionId
    };
    try {
        const headers = { headers: { 'auth-token': yield select(makeSelectAuthToken()) } };
        const response = yield axios.post(`recommendations`, data, headers);
        if (response.data.message == 'Deleted') {
            yield SetNotification('success', 'Skill UnRecommended');
        } else {
            yield SetNotification('success', 'Skill Recommended');
        }
        yield put(
            getTroubleSpotsList({
                InstitiutionId: payload.InstitutionId,
                limit: payload.limit,
                page: payload.page,
                search: payload.search
            })
        );
    } catch (error) {
        yield sagaErrorHandler(error.response.data);
    }
}

export function* WatchRecommendSkill() {
    yield takeLatest(RECOMMEND_SKILL, recommendSkillRequest);
}

export default function* troubleSpotsSaga() {
    yield all([fork(WatchGetAllTroubleSpots), fork(WatchRecommendSkill)]);
}
