import produce from 'immer';
import {
    LOGIN_SUCCESS,
    LOGOUT,
    GET_ALL_PLANS_SUCCESS,
    CHANGE_PLAN_SUCCESS,
    SET_INSTITUTION_TYPE,
    SET_LOADER,
    SET_CARD_DETAILS,
    UPDATE_PROFILE_SUCCESS
} from './constants';

const INITIAL_STATE = {
    user: null,
    token: '',
    cardDetails: null,
    subStatus: null,
    plans: [],
    institutionType: 'school',
    loader: false,
  
};

const AuthReducer = produce((draft, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            draft.user = action.payload.user;
            draft.token = action.payload.auth;
            draft.cardDetails = action.payload.cardDetails;
            draft.subStatus = action.payload.subStatus;
            draft.institution = action.payload.institution;
            break;
        case UPDATE_PROFILE_SUCCESS:
            draft.user = action.payload;
            break;
        case GET_ALL_PLANS_SUCCESS:
            draft.plans = action.payload;
            break;
        case CHANGE_PLAN_SUCCESS:
            draft.user = action.payload.user && action.payload.user;
            draft.subStatus = action.payload.subStatus && action.payload.subStatus;
            break;
        case SET_INSTITUTION_TYPE:
            draft.institutionType = action.payload;
            break;
        case SET_CARD_DETAILS:
            draft.cardDetails = action.payload;
            break;
        case SET_LOADER:
            draft.loader = action.payload;

            break;
          
        case LOGOUT:
            draft.user = '';
            draft.token = '';
            break;
        default:
    }
}, INITIAL_STATE);

export default AuthReducer;
