import axios from '../../../utils/axios';
import { all, call, fork, put, retry, takeLatest, select } from 'redux-saga/effects';
import { TEACHER_LIST, ADD_NEW_TEACHER, DELETE_TEACHER, EDIT_TEACHER, ASSIGNED_TEACHER, GET_TEACHER_DETAIL } from './constants';
import { getTeacherListSuccess, assigndTeacherSuccess, getTeacherList, setLoader, getTeacherDetailSuccess } from './actions';
import { sagaErrorHandler } from 'shared/helperMethods/sagaErrorHandler';
import { makeSelectAuthToken } from 'store/Selector';
import { SetNotification } from 'shared/helperMethods/setNotification';

function* getAllTeacherRequest({ payload }) {
    try {
        const headers = { headers: { 'auth-token': yield select(makeSelectAuthToken()) } };
        const response = yield axios.get(
            `/teachers/byInstituionId/${payload.InstitiutionId}?limit=${payload.limit}&page=${payload.page}&search=${payload.search}`,
            headers
        );

        yield put(getTeacherListSuccess(response.data.data));
    } catch (error) {
        yield sagaErrorHandler(error.response.data);
    }
}

export function* WatchGetAllTeacher() {
    yield takeLatest(TEACHER_LIST, getAllTeacherRequest);
}

function* deleteTeacherRequest({ payload }) {
    try {
        const headers = { headers: { 'auth-token': yield select(makeSelectAuthToken()) } };
        const response = yield axios.delete(`/teachers/${payload.teacherId}`, headers);
        yield put(
            getTeacherList({ InstitiutionId: payload.institiutionId, limit: payload.limit, page: payload.page, search: payload.search })
        );
        payload.handleClose();
        yield put(setLoader(false));
        yield SetNotification('success', response.data.message);
    } catch (error) {
        yield put(setLoader(false));
        yield sagaErrorHandler(error.response);
    }
}

export function* watchDeleteTeachers() {
    yield takeLatest(DELETE_TEACHER, deleteTeacherRequest);
}

///</teacher ASSIGNED/>////
function* teacherAssigned({ payload }) {
    const headers = { headers: { 'auth-token': yield select(makeSelectAuthToken()) } };
    try {
        let response = yield axios.get(`teachers/ByInstitionId/dropDown/${payload.institiutionId}`, headers);
        let responseData = response.data.data;

        yield put(assigndTeacherSuccess(responseData));
    } catch (error) {
        yield sagaErrorHandler(error.responseData);
    }
}

export function* WatchTeacherAssigning() {
    yield takeLatest(ASSIGNED_TEACHER, teacherAssigned);
}

///</add teacher />////
function* addTeacher({ payload }) {
    const headers = { headers: { 'auth-token': yield select(makeSelectAuthToken()) } };

    let data = {
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email,
        password: payload.password,
        phone: payload.phone,
        address: payload.address,
        userType: payload.userType,
        Teachers: {
            name: payload.firstName + payload.lastName,
            InstitutionId: payload.InstitutionId
        }
    };

    try {
        const response = yield axios.post(`/teachers`, data, headers);
        payload.navigate('/teachers');
        yield SetNotification('success', response.data.message);
    } catch (error) {
        yield sagaErrorHandler(error?.response?.data);
    }
}

export function* watchAddTeachers() {
    yield takeLatest(ADD_NEW_TEACHER, addTeacher);
}

///</Delete teacher />////

///</Delete teacher />////

function* editTeachers({ payload }) {
    const headers = { headers: { 'auth-token': yield select(makeSelectAuthToken()) } };

    let data = {
        firstName: payload.firstName,
        lastName: payload.lastName,
        phone: payload.phone,
        address: payload.address
    };
    try {
        const response = yield axios.put(`/teachers/${payload.teacherId}`, data, headers);
        payload.navigate('/teachers');
        yield SetNotification('success', response.data.message);
    } catch (error) {
        yield sagaErrorHandler(error.response);
    }
}

export function* watchEditTeachers() {
    yield takeLatest(EDIT_TEACHER, editTeachers);
}

function* getTeacherDetailRequest({ payload }) {
    try {
        const headers = { headers: { 'auth-token': yield select(makeSelectAuthToken()) } };
        const response = yield axios.get(`/teachers/${payload.id}/details`, headers);
        let teacherDetail = response.data.data;

        let data = {
            firstName: teacherDetail?.User.firstName,
            lastName: teacherDetail?.User.lastName,
            phoneNo: teacherDetail?.User.phone,
            address: teacherDetail?.User.address,
            email: teacherDetail?.User.email
        };
        yield put(getTeacherDetailSuccess(data));
    } catch (error) {
        yield sagaErrorHandler(error.response);
    }
}

export function* watchGetTeacherDetail() {
    yield takeLatest(GET_TEACHER_DETAIL, getTeacherDetailRequest);
}

export default function* teacherSaga() {
    yield all([
        fork(WatchGetAllTeacher),
        fork(watchAddTeachers),
        fork(watchDeleteTeachers),
        fork(watchEditTeachers),
        fork(WatchTeacherAssigning),
        fork(watchGetTeacherDetail)
    ]);
}
