import {
    SET_LOADER,
    GET_ALL_GRADES,
    GET_ALL_GRADES_SUCCESS,
    ADD_GRADES,
    DELETE_GRADES,
    EDIT_GRADES,
    GET_GRADE_DETAIL,
    GET_GRADE_DETAIL_SUCCESS
} from './constants';

export const getGradeDetail = (data) => {
    return {
        type: GET_GRADE_DETAIL,
        payload: data
    };
};
export const getGradeDetailSuccess = (data) => {
    return {
        type: GET_GRADE_DETAIL_SUCCESS,
        payload: data
    };
};

export const setLoader = (data) => {
    return {
        type: SET_LOADER,
        payload: data
    };
};

export const getAllGrades = (data) => {
    return {
        type: GET_ALL_GRADES,
        payload: data
    };
};
export const getAllGradesSuccess = (data) => {
    return {
        type: GET_ALL_GRADES_SUCCESS,
        payload: data
    };
};

export const addGrades = (data) => {
    return {
        type: ADD_GRADES,
        payload: data
    };
};
export const deleteGrades = (data) => {
    return {
        type: DELETE_GRADES,
        payload: data
    };
};
export const editGrades = (data) => {
    return {
        type: EDIT_GRADES,
        payload: data
    };
};
