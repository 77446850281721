import React, { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import SuperAdminGuard from './SuperAdminGuard';
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
const SuperAdminDashboard = Loadable(lazy(() => import('views/pages/superAdmin/dashboard')));
const Homes = Loadable(lazy(() => import('views/pages/superAdmin/home')));
const Schools = Loadable(lazy(() => import('views/pages/superAdmin/school')));
const Levels = Loadable(lazy(() => import('views/pages/superAdmin/levels')));
const Skills = Loadable(lazy(() => import('views/pages/superAdmin/skills')));
const Questions = Loadable(lazy(() => import('views/pages/superAdmin/skills/questions')));
const Users = Loadable(lazy(() => import('views/pages/superAdmin/users')));
const AppUserAccountProfile = Loadable(lazy(() => import('views/users')));

// ==============================|| MAIN ROUTING ||============================== //

const SuperAdminRoutes = {
    id: 'super_admin',
    path: '/',
    element: (
        <SuperAdminGuard>
            <MainLayout />
        </SuperAdminGuard>
    ),
    type: 'group',
    children: [
        {
            path: '/dashboard',
            element: <SuperAdminDashboard />
        },
        {
            path: '/schools',
            element: <Schools />
        },
        {
            path: '/homes',
            element: <Homes />
        },
        {
            path: '/levels',
            element: <Levels />
        },
        {
            path: '/users',
            element: <Users />
        },
        {   
            // type: 'item',
            path: '/levels/skills',
            element: <Skills />,
            // breadcrumbs: false
              

        },
        {
            path: '/levels/skills/questions',
            element: <Questions />
        },
        {
            path: '/user',
            element: <AppUserAccountProfile />
        },
    ]
};

export default SuperAdminRoutes;
