import React from 'react';
import { useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
// routes
import SuperAdminRoutes from './superAdmin/SuperAdminRoutes';
import SchoolAdminRoutes from './schoolAdmin/SchoolAdminRoutes';
import ParentRoutes from './parent/ParentRoutes';
import TeacherRoutes from './teacher/TeacherRoutes';
import LoginRoutes from './LoginRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const userData = useSelector((state) => state.auth);
    if (userData.user && userData.user.userType == 'super_admin') {
        return useRoutes([SuperAdminRoutes, LoginRoutes, AuthenticationRoutes]);
    } else if (userData.user && userData.user.userType == 'school_admin') {
        return useRoutes([SchoolAdminRoutes, LoginRoutes, AuthenticationRoutes]);
    } else if (userData.user && userData.user.userType == 'parent') {
        return useRoutes([ParentRoutes, LoginRoutes, AuthenticationRoutes]);
    } else if (userData.user && userData.user.userType == 'teacher') {
        return useRoutes([TeacherRoutes, LoginRoutes, AuthenticationRoutes]);
    } else {
        return useRoutes([LoginRoutes, AuthenticationRoutes]);
    }
}
